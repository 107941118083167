
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}


body {
  background-color: #FCFAFA;
  margin: 0;
  font-family: 'Livvic', sans-serif;
  overflow-x: hidden;
  text-align: center;
  font-size: 16px;

}
.img-responsive {
  height: 400px;
   width:inherit; 
  object-fit: cover; 
  /* align-self: center; */
  /* border: 1px solid black; */
  
}
.project-card {
  /* width: 30vw; */
  align-self: center;
  padding:10px;
  margin:5px;
  
}



a {
  text-decoration:none;
  color: black;
  font-size: 26px;
}

#fs-frm-inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
 
 

}
.contact {
  background:url('https://profunda-analytics.com/wp-content/uploads/2017/07/Contact-Form-background.jpg') no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  
  display:flex;
  justify-content: center;
  
  padding: 30px;
  
}
input[type=submit] {
  background-color: pink;
  width:fit-content;
}
input,textarea {
  border-radius: 5px;
  font-family: inherit;
  font-size: 1rem;
  color: black;
  padding: 0.75rem;
  border: none;
  margin: 1rem auto;
  width: 30vw;

}
.contact-span {
  background-color: #99999975;
}
#fs-frm {
  width: 600px;
  font-size: 26px;
}
.title {
  font-weight: bold;
  text-underline-position: below;
  color: black;
}
.title:hover {
  color:whitesmoke;
  cursor: pointer;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.link-item {
  font-size: 26px;
  cursor: pointer;
}
.logo, .logo-round {
  padding: 10px;
  margin: 5px;
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 20%;
}

.logo-round {
  border-radius: 50%;
}

.logo:hover, .logo-round:hover {
  box-shadow: rgb(182, 176, 176);
}
.moodi {
  object-fit: cover;
  border-radius: 20%;
}
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
 
  
  
}
.container:hover {
  z-index: 0;
  box-shadow: 0px 10px 100px -10px rgba(0, 0, 0, 0.4);
  transition-property: box-shadow, z-index;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}
.contact:hover {
  z-index: 0;
  box-shadow: 0px 10px 100px -10px rgba(0, 0, 0, 0.4);
  
  transition-property: box-shadow, z-index;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.contact-wrap:hover .contact,
.contact-wrap:focus .contact {
  transform: scale(1.1);
  transition: all .5s ease-out;
}
.section-title {
  font-size: 26px;
}
.card {
  border: 1px solid lightgray
}

.card:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition: opacity 300ms ease-in, -webkit-transform 200ms ease-in-out;
  transition: opacity 300ms ease-in, -webkit-transform 200ms ease-in-out;
  transition: opacity 300ms ease-in, transform 200ms ease-in-out;
  transition: opacity 300ms ease-in, transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;

}

.card-body:hover{
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 3px;
  opacity:1 !important;
  background-color: #fcfafad1;
  background-image: linear-gradient( 180.1deg,  rgba(246, 245, 245, 0.596) 32.8%, rgba(41, 41, 41, 0.637) 94.7% );
  -webkit-transform: translateY(0);
          transform: translateY(0);
          
  padding:50px;
  transition: width 1s, background-color 0.5s ;

}
/* .the-card:hover {
  background-image: linear-gradient( 69.9deg,  rgba(76,79,106,1) 3.2%, rgba(118,124,163,1) 97.6% );
} */
.card-details:hover {
  
  /* background-color: rgba(128, 128, 128, 0.685);
  transition: width 2s, background-color 0.5s ; */
  /* animation: grow 0.5s forwards; */
  font-weight: bold;
}
.card-title {
  font-weight: bold;
  font-stretch: expanded;
  font-size:26px;
}
.card-body:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top:90px;
  left:0;
  background-color: #000;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  
} 

.card-body:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  
}

@keyframes grow{
  0% {
    height: 100px;
    
  }
  
  100% {
    height: 210px;
    padding:0;
    transition: width 2s, background-color 0.5s ;
    background-image: linear-gradient( 180.1deg,  rgba(246,245,245,1) 32.8%, rgba(66, 66, 66, 0.52) 94.7% );
    
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  margin-bottom:0 !important;
  margin-top: 20px;
}



.typed-text{
  font-family: 'Space Mono', monospace;
  font-size: 26px;
  margin-top:5px;
  margin-bottom: 0;

}
header {
  font-size: 20px;
  text-align: left;
 
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center; */
}
.navigation {
  
  font-size: 20px;
  margin-top: 0px;
  width: 100%;
  position: fixed;
  
  top: 0;
  z-index: 99;
  background-color: #FCFAFA;
}
.link-items {
  display: flex;
  width: 100vw;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: #FCFAFA; */
}



.flex-row{
  margin:10px;
  padding: 20px;
  font-size: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* height: 90vh */
}

.quote {
  display: flex;
  width: 300px;
}

.coding-pic {
  
  width: 100vw;
  height: 88vh;
  background: url('./assets/coding.jpg') no-repeat center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding:20px;
  transition: background-image 1s ease-in-out;
  
}


.project-pic {
  width: 100vw;
  height: 60vh;
  background: url('https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80') no-repeat center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.profile-pic {
  width:200px;
  object-fit: cover;
  height: 200px;
  border-radius: 50%;
  border: 1px solid gray;
}


.skill-icon {
  width: 80px;
  height: 80px;
  padding: 10px;
  margin:2px;
  background-color: transparent;
  border-radius: 20%;
}
.skill-icon:hover {
  cursor: pointer;
  /* transform: translateY(0); */
  background-color: rgba(255, 255, 255, 0.527);
  /* transform: scale(1.15); */
  opacity:0.8;
  -webkit-transition: background-color 300ms ease-in-out ;
  -moz-transition: background-color 300ms ease-in-out ;
  -o-transition: background-color 300ms ease-in-out ;
  transition: background-color 300ms ease-in-out ;
  animation: wiggle 1s ease;
  animation-iteration-count: 1;
}
.skills {
  padding: 30px;
}

@keyframes wiggle {
  20%  { transform: translateY(4px);  }
  40%  { transform: translateY(-4px); }
  60%  { transform: translateY(2px);  }
  80%  { transform: translateY(-1px); }
  100% { transform: translateY(0);    }
}

footer {
  margin: 10px;
	width: 100vw;
	background-color: #FCFAFA;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-weight: bold;
	align-items: center;
}

.logo-small {
  border-radius: 50%;
  object-fit: cover;
  
 
  width: 50px;
  height: 50px;
}

.message {
  margin: 0 !important;
}
.hearts {
  color: red;
}

.icons {
  padding-top: 20px;
  width: 200px;
  display: flex;
  justify-content: space-evenly;
}

.navbar {
  padding: 0;
  background-color: #FCFAFA;
}

.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.about-section{
  margin: 10px;
  padding: 10px;
}

.dropdown-item.active, .dropdown-item:active {
  background: none;
}

.dropdown-item {
  padding: 10px;
  color: rgb(87, 86, 86)
}
.dropdown-item.active {
  color: black;
 
}
.navbar {
  height: 80px;
}
.the-card {
  width: 24rem;
  height: 24rem;
}
#portfolio {
  margin: 2em;
}

 

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 667px) and (min-device-width: 375px) {
  body,html {
    overflow-x: hidden;
    width: 100%;
  }
  .navbar {
   
    padding: 15px;
    height: auto;
   
  } 
  .navbar-toggler {
    position:-webkit-sticky;
  }
  .link-items {
    display: unset;
  } 
  
  .container {
    display: unset;
    width: auto;
  }
  .coding-pic {
    padding: 0 !important;
    margin-top: 0 !important;
    
   
  }
  header {
    background-color: rgba(0, 0, 0, 0.431);
  }
  input,textarea {
    width: 90vw;
  }
  .about-me {
    display: unset;
  }
  .quote {
    padding-top: 10px;
    width: auto;
  }
  .skills {
    padding-top:5px;
  }
  .coding-pic {
    height: 80vh;
    background-attachment: unset;
  }

  .about-section {

    display: flex;
    flex-direction: column;
  }
  .project-card {
    padding: 10px;
    margin: 0;
  }
  .container {
    padding: 10px;
  }
  .project-pic {
  background: url('https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  }
  .contact {
    background:url('https://profunda-analytics.com/wp-content/uploads/2017/07/Contact-Form-background.jpg') no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  }
  .the-card {
    width: auto;
  }
  .skills {
    padding: 0;
  }
  
  
  
}
  
  

